import {Injectable} from "@angular/core";
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from "@angular/common/http";
import {Observable} from "rxjs";
import {tap} from "rxjs/operators";
import {Store} from "@ngrx/store";
import {Router} from "@angular/router";
import {unlockItemActions} from "@monorepo/item/ui/unlock/store";

@Injectable()
export class BlockedItemsInterceptor implements HttpInterceptor {
  constructor(private store: Store, private readonly router: Router) {
  }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return next.handle(req).pipe(
        tap({
          error: (error) => {
            if (error instanceof HttpErrorResponse) {
              if (error.status === 406 && error.error?.error === 'Blocked Items') {
                const returnUrl = this.router.url;
                this.store.dispatch(unlockItemActions.open.trigger({ sscc: error.error?.ssccValue, returnUrl }))
                console.log('got blocked items error')
              }
            }
          }
        })
      )
    }

}
