import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { combineLatest, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { exhaustMap, tap, take } from 'rxjs/operators';
import { authActions, AuthService } from '@monorepo/auth/ui';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private store: Store, private authService: AuthService) {}

  intercept(
    req: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return combineLatest([
      this.authService.token$,
      this.authService.workPlace$,
    ]).pipe(
      take(1),
      exhaustMap(([token, workPlace]) => {
        let headers = req.headers;
        if (token) {
          headers = headers.set('Authorization', `Bearer ${token}`);
        }
        if (workPlace) {
          headers = headers.set('Place', `${workPlace.id},${workPlace.name}`);
        }
        const authReq = req.clone({ headers });
        return next.handle(authReq).pipe(
          tap({
            next: (event) => {
              if (event instanceof HttpResponse) console.log('Server response');
            },
            error: (error) => {
              console.log(error);
              if (error instanceof HttpErrorResponse) {
                if (error.status == 401) {
                  this.store.dispatch(authActions.logout());
                }
              }
            },
          })
        );
      })
    );
  }
}
