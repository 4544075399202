import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { A11yModule } from '@angular/cdk/a11y';
import { MatStepperModule } from '@angular/material/stepper';

import { ScanRegistryComponent } from './components/scan-registry/scan-registry.component';
import { ScanSsccBoxComponent } from './components/scan-sscc-box/scan-sscc-box.component';
import { ScanSsccPalletComponent } from './components/scan-sscc-pallet/scan-sscc-pallet.component';
import { ScanFieldComponent } from './components/scan-field/scan-field.component';
import { ScanSkuComponent } from './components/scan-sku/scan-sku.component';
import { ChooseConditionComponent } from './components/choose-condition/choose-condition.component';
import { FormAllErrorsComponent } from './components/form-errors/form-errors.component';
import { ScanSsccBoxOrPalletComponent } from './components/scan-sscc-box-or-pallet/scan-sscc-box-or-pallet.component';
import { ScanMarkingCodeComponent } from './components';
import { ScanUiidComponent } from './components/scan-uiid/scan-uiid.component';
import { DimensionsFormComponent } from './components/dimensions-form/dimensions-form.component';
import { ScanUiidOrSkuComponent } from './components/scan-uiid-or-sku/scan-uiid-or-sku.component';
import { CustomMatStepComponent } from './components/stepper/mat-step-base';
import { AssignUserComponent } from './components/assign-user/assign-user.component';
import { SetPriorityComponent } from './components/set-priority/set-priority.component';
import { ChooseEntityComponent } from './components/choose-entity';
import {InputControl} from "@12stz/ui-kit/controls/input";
import {IconModule} from "@12stz/ui-kit/atoms/icon";
import {ControlViewModule} from "@12stz/ui-kit/molecules/control-view";
import {ButtonDirective} from "@12stz/ui-kit/atoms/button";
import {LoadingDirective} from "@12stz/ui-kit/quarks/directives";

const Forms = [
  ScanRegistryComponent,
  ScanSsccBoxComponent,
  ScanSsccPalletComponent,
  ScanSsccBoxOrPalletComponent,
  ScanFieldComponent,
  ScanSkuComponent,
  ScanUiidComponent,
  ChooseConditionComponent,
  ScanMarkingCodeComponent,
  ScanUiidComponent,
  DimensionsFormComponent,
  ScanUiidOrSkuComponent,
  CustomMatStepComponent,
];

const Standalone = [
  FormAllErrorsComponent,
  AssignUserComponent,
  SetPriorityComponent,
  ChooseEntityComponent,
];

@NgModule({
  declarations: Forms,
  exports: [...Forms, ...Standalone],
  imports: [
    ...Standalone,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    MatSelectModule,
    A11yModule,
    MatStepperModule,
    MatIconModule,
    InputControl,
    IconModule,
    ControlViewModule,
    ButtonDirective,
    LoadingDirective,
  ],
})
export class SharedFormsModule {}
