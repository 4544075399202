import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ValidatorFn } from '@angular/forms';
import {
  CUSTOM_VALIDATORS,
  SharedFormsModule,
} from '@monorepo/ui/components/forms';

@Component({
  selector: 'monorepo-change-place',
  standalone: true,
  imports: [CommonModule, SharedFormsModule],
  template: `
    <div class="col gap-8">
      <div class="sz-body-large">
        {{ allowedPlaces?.length | i18nPlural: messageMapping }}
        <b>{{ allowedPlaces?.join(', ') }}</b>
      </div>
      <div class="sz-body-large">Ваше РМ: {{ currentPlaceName }}</div>
      <forms-base-scan-field
        label="Отсканируйте место"
        [loading]="loading"
        [validators]="placeValidator"
        (formSubmit)="scanPlace.next($event)"
      >
      </forms-base-scan-field>
    </div>
  `,
})
export class ChangePlaceComponent implements OnInit {
  @Input() loading: boolean | null = false;
  @Input() currentPlaceName: string | null = '';
  @Input() allowedPlaces: string[] | null = [];
  @Output() scanPlace = new EventEmitter<string>();
  messageMapping = { '=1': 'Подходящее место:', other: 'Подходящие места:' };
  public placeValidator: ValidatorFn[] = [];
  ngOnInit() {
    if (this.allowedPlaces) {
      this.placeValidator.push(
        CUSTOM_VALIDATORS.allowedPlaces(this.allowedPlaces)
      );
    }
  }
}
