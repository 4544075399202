import {Inject, Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {API_URL} from "@monorepo/ui/core";
import {makeUrl} from "@monorepo/ui/helpers";
import { ListGroupDto } from "@monorepo/dictionaries/dto";
import { DICTIONARIES_LIST_GROUPS_API } from "@monorepo/dictionaries/shared";

const { CONTROLLER, ALL } = DICTIONARIES_LIST_GROUPS_API;
@Injectable({
  providedIn: 'root',
})
export class ListGroupsHttpService {
  constructor(
    private http: HttpClient,
    @Inject(API_URL) private apiUrl: string
  ) {}

  private BASE_URL = () => makeUrl(this.apiUrl, 'api/v3', CONTROLLER().url);

  getAll() {
    const { url } = ALL();
    const URL = makeUrl(this.BASE_URL(), url);
    return this.http.get<ListGroupDto[]>(URL);
  }
}
