import {Injectable} from "@angular/core";
import {Actions, concatLatestFrom, createEffect, ofType} from "@ngrx/effects";
import {catchError, exhaustMap, map, tap} from "rxjs/operators";
import {of} from "rxjs";
import {isFresh} from "@monorepo/ui/helpers";
import {Store} from "@ngrx/store";
import {SnackbarService} from "@monorepo/ui/services/snackbar";
import {mistakesActions} from "./mistakes.actions";
import {MistakesAllService} from "../mistakes-all.service";
import {mistakesSelectors} from "./mistakes.selector";

@Injectable()
export class MistakesEffects {
  getData_trigger$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(mistakesActions.getData.trigger),
      exhaustMap(() => {
        return this.mistakesService.getData().pipe(
          map((response) => {
            return mistakesActions.getData.success({ data: response.data });
          }),
          catchError((error) => {
            return of(mistakesActions.getData.failure({ error }));
          })
        );
      })
    );
  });

  getData_checkFreshness$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(mistakesActions.getData.checkFreshness),
      concatLatestFrom(() => this.store.select(mistakesSelectors.state)),
      map(([, state]) => isFresh(state.updatedAt, state.cacheTime)),
      map((isFresh) => {
        return isFresh
          ? mistakesActions.getData.isFresh()
          : mistakesActions.getData.trigger();
      })
    );
  });

  failure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(mistakesActions.getData.failure),
        tap(({ error }) => {
          this.snackbarService.catchApiError({ error });
        })
      );
    },
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private snackbarService: SnackbarService,
    private mistakesService: MistakesAllService
  ) {}
}
