import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import { lastValueFrom } from 'rxjs';
import {
  SnackbarData,
  TSnackMessageOrTitle,
  TSnackType,
} from './snackbar.interfaces';
import { SnackbarComponent } from './snackbar/snackbar.component';

type TSnack = {
  data: SnackbarData;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  config?: MatSnackBarConfig<any>;
  created?: (snack: MatSnackBarRef<SnackbarComponent>) => void;
};

type TTypedSnack = Omit<TSnack, 'data'> & TSnackMessageOrTitle;

type TApiErrorSnack = {
  error: HttpErrorResponse | string | any;
  fallback?: TSnackMessageOrTitle;
};

@Injectable({ providedIn: 'root' })
export class SnackbarService {
  _currentSnack?: MatSnackBarRef<SnackbarComponent>;
  _snackArr: TSnack[] = [];

  SNACKBAR_BASE_CONFIG: MatSnackBarConfig = {
    duration: 5000,
    horizontalPosition: 'right',
    verticalPosition: 'top',
  };

  SNACKBAR_CONFIGS: Record<
    'success' | 'warn' | 'error' | 'info',
    MatSnackBarConfig
  > = {
    success: { ...this.SNACKBAR_BASE_CONFIG, duration: 1000 },
    error: this.SNACKBAR_BASE_CONFIG,
    warn: this.SNACKBAR_BASE_CONFIG,
    info: this.SNACKBAR_BASE_CONFIG,
  };

  constructor(private matSnackBar: MatSnackBar) {}

  add(props: TSnack) {
    this._snackArr.push(props);
    if (!this._currentSnack) {
      this.nextSnackbar();
    }
  }

  success({ title, message, ...props }: TTypedSnack) {
    const type = 'success';
    if (title || message) {
      this.add({
        ...props,
        config: { ...this.SNACKBAR_CONFIGS[type], ...props.config },
        data: { type, message: message, title: <string>title },
      });
    }
  }

  error({ title, message, ...props }: TTypedSnack) {
    const type = 'error';
    if (title || message) {
      this.add({
        ...props,
        config: { ...this.SNACKBAR_CONFIGS[type], ...props.config },
        data: { type, message: message, title: <string>title },
      });
    }
  }

  warn({ title, message, ...props }: TTypedSnack) {
    const type = 'warn';
    if (title || message) {
      this.add({
        ...props,
        config: { ...this.SNACKBAR_CONFIGS[type], ...props.config },
        data: { type, message: message, title: <string>title },
      });
    }
  }

  info({ title, message, ...props }: TTypedSnack) {
    const type = 'info';
    if (title || message) {
      this.add({
        ...props,
        config: { ...this.SNACKBAR_CONFIGS[type], ...props.config },
        data: { type: 'info', message: message, title: <string>title },
      });
    }
  }

  catchApiError(errorRes: HttpErrorResponse): void;
  catchApiError(errorAndFallback: TApiErrorSnack & { type?: TSnackType }): void;
  catchApiError(errorStr: string): void;
  catchApiError(
    data: HttpErrorResponse | (TApiErrorSnack & { type?: TSnackType }) | string
  ) {
    let type: TSnackType = 'error';
    let error: TApiErrorSnack['error'];
    let fallback: TApiErrorSnack['fallback'];

    if (data instanceof HttpErrorResponse) {
      // todo: temporary fix for v1,v2 backend
      const isV3Url = data.url?.includes('api/v3');
      error = isV3Url ? { error: data.error } : data?.error?.error;
    } else if (typeof data === 'string') {
      error = data;
    } else {
      error = data.error;
      type = data.type || type;
      fallback = data.fallback;
    }

    const message =
      typeof error === 'string'
        ? error
        : error?.error?.message || fallback?.message || 'Что-то пошло не так';
    const title = error?.error?.title || fallback?.title;
    return this[type]({ title, message });
  }

  private nextSnackbar() {
    if (this._snackArr[0]) {
      const { data, config, created } = this._snackArr[0];

      this._snackArr.shift();

      this._currentSnack = this.matSnackBar.openFromComponent(
        SnackbarComponent,
        { ...this.SNACKBAR_BASE_CONFIG, ...config, data }
      );

      if (created) {
        created(this._currentSnack);
      }

      lastValueFrom(this._currentSnack.afterDismissed())
        .then(() => {
          this._currentSnack = undefined;
          this.nextSnackbar();
        })
        .catch(() => {
          this._currentSnack = undefined;
          this.nextSnackbar();
        });
    }
  }
}
