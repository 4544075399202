import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { SnackbarService } from '@monorepo/ui/services/snackbar';
import { catchError, exhaustMap, map, tap } from 'rxjs/operators';
import { inventoryListStatusesActions } from './inventory-list-statuses.actions';
import { inventoryListStatusesSelectors } from './inventory-list-statuses.selectors';
import { InventoryListStatusesService } from '../inventory-list-statuses.service';
import { isFresh } from '@monorepo/ui/helpers';

@Injectable()
export class InventoryListStatusesEffects {
  getData_trigger$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(inventoryListStatusesActions.getData.trigger),
      exhaustMap(() => {
        return this.inventoryListStatusesService.getData().pipe(
          map((data) => {
            return inventoryListStatusesActions.getData.success({ data });
          }),
          catchError((error) => {
            return of(inventoryListStatusesActions.getData.failure({ error }));
          })
        );
      })
    );
  });

  getData_checkFreshness$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(inventoryListStatusesActions.getData.checkFreshness),
      concatLatestFrom(() =>
        this.store.select(inventoryListStatusesSelectors.state)
      ),
      map(([, state]) => isFresh(state.updatedAt, state.cacheTime)),
      map((isFresh) => {
        return isFresh
          ? inventoryListStatusesActions.getData.isFresh()
          : inventoryListStatusesActions.getData.trigger();
      })
    );
  });

  failure$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(inventoryListStatusesActions.getData.failure),
        tap(({ error }) => {
          this.snackbarService.catchApiError({ error });
        })
      );
    },
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private snackbarService: SnackbarService,
    private inventoryListStatusesService: InventoryListStatusesService
  ) {}
}
